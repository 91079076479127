$(document).on('turbolinks:load', function() {
  $(document).on('click', '#white-labeling-check', function() {
    if ($(this).prop('checked')) {
      $('.white-labeling-attributes').removeAttr('hidden');
    }else {
      $('.white-labeling-attributes').prop('hidden', true); 
    }
  });
  $(document).on('click', '#account_snds', function() {
    $('#account_snds_key, #submit-snds-key').prop('disabled', !($(this).prop('checked'))); 
  });
});
