$(document).on('turbolinks:load', function () {
  $(".block-to-pop").click(function(){
    let div_to_close = $(".collapse.show")
    if (div_to_close != null){
      $(".block-fx-pop").removeClass("block-fx-pop");
      div_to_close.removeClass("show");
    }
    $(this).addClass("block-fx-pop"); 
  });
});
