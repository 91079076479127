$(document).keydown(function(event) { 
  if (event.keyCode == 27) { 
    $('#modal-window').modal('hide');
  }
});
$(document).keydown(function(event) { 
  if (event.keyCode == 27) { 
    $('#email-preview').modal('hide');
  }
});
