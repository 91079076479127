function switchPolicyView(radio_btn) {
  if (radio_btn.val() == 'email'){
    $('.webhook-group').attr('hidden', 'hidden');
    $('.phone-checkboxes').attr('hidden' , 'hidden');
    $('.webhook-types').attr('hidden' , 'hidden');
    $('.email-checkboxes').removeAttr('hidden');
    $('.phone-checkboxes input').prop('checked', false);
  }
  else if (radio_btn.val() == 'sms') {
    $('.email-checkboxes').attr('hidden' , 'hidden');
    $('.webhook-group').attr('hidden' , 'hidden');
    $('.webhook-types').attr('hidden' , 'hidden');
    $('.phone-checkboxes').removeAttr('hidden');
    $('.email-checkboxes input').prop('checked', false);
  }
  else if (radio_btn.val() == 'webhook') {
    $('.email-checkboxes').attr('hidden' , 'hidden');
    $('.phone-checkboxes').attr('hidden' , 'hidden');
    $('.webhook-group').removeAttr('hidden');
    $('.webhook-types').removeAttr('hidden');
    $('.email-checkboxes input').prop('checked', false);
    $('.phone-checkboxes input').prop('checked', false);
  }
}

function emptyExtraFields(radio_btn) {
  if(radio_btn.val() == 'email') {
    $('.phone-checkboxes input').prop('checked', false);
    $('#notification_policy_webhook_link').attr('value', '');
    $('#notification_policy_webhook_payload').val('').change();
  }
  else if(radio_btn.val() == 'sms') {
    $('.email-checkboxes input').prop('checked', false);
    $('#notification_policy_webhook_link').val('');
    $('#notification_policy_webhook_payload').val('').change();
  }
  else if(radio_btn.val() == 'webhook'){
    $('.email-checkboxes input').prop('checked', false);
    $('.phone-checkboxes input').prop('checked', false);
  }
}

function switchPayloadForWebhook(webhook_type_btn){
  if (webhook_type_btn.val() == 'custom'){
    $('.webhook-payload-field').removeAttr('readonly');
    var payload = $('.webhook-fields').data('custom-payload');
    $('.webhook-payload-field').val(JSON.stringify(payload)).change();
  }
  else if (webhook_type_btn.val() == 'slack') {
    $('.webhook-payload-field').prop('readonly', true);
    var payload = $('.webhook-fields').data('slack-payload');
    $('.webhook-payload-field').val(JSON.stringify(payload)).change();
  }
  else if (webhook_type_btn.val() == 'teams') {
    $('.webhook-payload-field').prop('readonly', true);
    var payload = $('.webhook-fields').data('teams-payload');
    $('.webhook-payload-field').val(JSON.stringify(payload)).change();
  }
}

function toggleEmailServices(services_checkbox){
  if (!services_checkbox.prop('checked')) {
    $("#all-email-services").removeAttr('hidden');
  }
  else{
    $("#all-email-services").attr('hidden' , 'hidden');
  }
}

$(document).on('turbolinks:load', function() {
  $('body').on('change' , 'div#radio-buttons input', function() {
    switchPolicyView($(this));
  });

  $('body').on('change' , 'div#webhook-types-radio-buttons input', function() {
    switchPayloadForWebhook($(this));
  });

  $('#policy-update-btn').click(function() {
    emptyExtraFields($('input[name="notification_policy[category]"]:checked'));
  });
  $('#notification_policy_is_all_services').click(function() {
    toggleEmailServices($(this));
  });
});
